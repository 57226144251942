import React from 'react'
import { Box, Image, Link, ThemeUIStyleObject } from 'theme-ui'

import facebookSocialIcon from '../images/social/facebook.svg'
import githubSocialIcon from '../images/social/github.svg'
import emailSocialIcon from '../images/social/email.svg'
import instagramSocialIcon from '../images/social/instagram.svg'
import linkedinSocialIcon from '../images/social/linkedin.svg'

const socialMediaIcons = [
  {
    link: 'https://www.facebook.com/jordan.manning.9028',
    icon: facebookSocialIcon,
    alt: 'Facebook',
  },
  {
    link: 'https://www.instagram.com/jordan._.manning/',
    icon: instagramSocialIcon,
    alt: 'Instagram',
  },
  {
    link: 'https://www.linkedin.com/in/jordan-manning-51bb49122/',
    icon: linkedinSocialIcon,
    alt: 'Linkedin',
  },
  {
    link: 'https://github.com/jordanmanning',
    icon: githubSocialIcon,
    alt: 'Github',
  },
  {
    link: 'mailto: hello@jordanmanning.co.uk',
    icon: emailSocialIcon,
    alt: 'Email',
  },
]

const socialMediaIconSize = 3.75
const socialMediaIconSpace = 2

const socialWrapperSx: ThemeUIStyleObject = {
  display: 'flex',
  justifyContent: 'space-evenly',
  maxWidth: [
    `${
      socialMediaIconSize * 0.7 * socialMediaIcons.length +
      socialMediaIconSpace * 0.7 * (socialMediaIcons.length - 1)
    }rem`,
    `${
      socialMediaIconSize * 0.8 * socialMediaIcons.length +
      socialMediaIconSpace * 0.8 * (socialMediaIcons.length - 1)
    }rem`,
    `${
      socialMediaIconSize * socialMediaIcons.length +
      socialMediaIconSpace * (socialMediaIcons.length - 1)
    }rem`,
  ],
  marginX: 'auto',
  marginTop: '2rem',
}

const socialIconSx: ThemeUIStyleObject = {
  width: [
    `${socialMediaIconSize * 0.7}rem`,
    `${socialMediaIconSize * 0.8}rem`,
    `${socialMediaIconSize}rem`,
  ],
  height: [
    `${socialMediaIconSize * 0.7}rem`,
    `${socialMediaIconSize * 0.8}rem`,
    `${socialMediaIconSize}rem`,
  ],
}

const SocialMedia = (): JSX.Element => (
  <Box sx={socialWrapperSx}>
    {socialMediaIcons.map((socialMediaIcon, index) => (
      <Link
        key={`social-icon-${index}`}
        href={socialMediaIcon.link}
        target="_blank"
      >
        <Image
          sx={socialIconSx}
          src={socialMediaIcon.icon}
          alt={socialMediaIcon.alt}
        />
      </Link>
    ))}
  </Box>
)

export default SocialMedia
