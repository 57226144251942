import React from 'react'
import SocialMedia from '../components/social-media'
import { Box, Heading, ThemeUIStyleObject } from 'theme-ui'

const containerSx: ThemeUIStyleObject = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
}

const textWrapperSx: ThemeUIStyleObject = {
  textAlign: 'center',
  mb: '1rem',
}

const h1Sx: ThemeUIStyleObject = {
  fontSize: ['3rem', '4rem', '5rem'],
  fontWeight: 'heading',
}

const h2Sx: ThemeUIStyleObject = {
  fontSize: ['1.8rem', '2.4rem', '3rem'],
  fontWeight: 'heading',
}

const h3Sx: ThemeUIStyleObject = {
  fontSize: ['1.5rem', '2rem', '2.5rem'],
  color: 'shamrock',
  fontWeight: 400,
  whiteSpace: [undefined, 'nowrap'],
}

const Index = (): React.ReactElement => (
  <Box sx={containerSx}>
    <Box sx={textWrapperSx}>
      <Heading as="h2" sx={h2Sx}>
        Hi my name is
      </Heading>
      <Heading as="h1" sx={h1Sx}>
        Jordan Manning
      </Heading>
      <Heading as="h3" sx={h3Sx}>
        Birmingham UK based Software Engineer
      </Heading>
    </Box>
    <SocialMedia />
  </Box>
)

export default Index
